import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SEO from '../components/seo';
import CustomLink from '../components/customLink';

import { toPath } from '../util';

const Title = styled.h1`
  font-size: 2.4em;
  text-align: center;
  font-weight: 400;
  font-family: 'Sorts Mill Goudy', serif;
`;

const LongWork = ({ data }) => {
  const { edges } = data.allContentfulWork;

  return (
    <>
      <SEO title="Long Work" description="G.S.M. Nui's long works" />
      <Title>
        Long Work
      </Title>
      {edges.map(({ node }) => (
        <CustomLink to={`/longwork/${toPath(node.title)}`} key={node.title} padding={5} color="black" size={1.6}>{node.title}</CustomLink>
      ))}
    </>
  );
};

LongWork.propTypes = {
  data: PropTypes.shape({
    allContentfulWork: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
          }),
        }),
      ),
    }),
  }).isRequired,
};

export default LongWork;

export const query = graphql`
  query LongWork {
    allContentfulWork(filter: {type: {eq: "Long Work"}}) {
      edges {
        node {
          title
        }
      }
    }
  }
`;
